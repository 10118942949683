<template>
  <div class="container">
    <van-nav-bar
      v-if="showHeader"
       style="z-index: 12"
      title="编辑海报"
      left-arrow
      @click-left="$router.back()"
    />
    <div v-if="previewObj.id" class="content">
      <div class="sampleImageBox columnCenter">
        <img
          :src="previewObj.sampleImgUrl"
          alt="图片加载失败，请联系管理员重新上传图片资源"
        />
      </div>
      <div class="bottomButBox">
        <div class="smallBut" @click="previewImg = true">
          <i class="iconfont iconyulan1"></i>
          <p class="smallButText">预览</p>
        </div>
        <div
          :class="['smallBut', { detailGuideCommon: detailGuide }]"
          @click="setTempCommon"
        >
          <i
            :class="[
              'iconfont',
              { iconsheweichangyong1: !previewObj.isCommon },
              { iconsheweichangyongyixuan: previewObj.isCommon }
            ]"
          ></i>
          <p class="smallButText">常用</p>
        </div>
        <button class="setUsually" @click="showPopup">开始制作</button>
      </div>
    </div>
    <van-overlay
      :show="showPopupContent"
      :lock-scroll="false"
      @click.stop="showPopupContent = listGuide"
    >
      <div
        v-if="listGuideStep != 3"
        :class="['makeBox flexColumn', { guidePopup: listGuide }]"
        @click.stop
      >
        <h4 class="makeBoxTitle">两步生成海报</h4>
        <van-form
          class="vanFromBox"
          :show-error-message="false"
          @submit="onSubmit"
          @failed="onFailed"
        >
          <div class="formBox">
            <div
              v-for="(imgItem, imgIndex) in imageElems"
              :key="'img' + imgIndex"
              class="imguploaderBox"
            >
              <p class="imguploaderLabel">{{ imgItem.key }}</p>
              <van-field
                :name="imgItem.key"
                :rules="[{ required: true, message: '请上传' + imgItem.key }]"
              >
                <template #input>
                  <van-uploader
                    v-model="imgItem.imgUrl"
                    :name="imgItem.key"
                    accept="image/jpeg,image/jpg,image/png"
                    :before-read="beforeRead"
                    :max-count="1"
                  >
                    <div class="uploaderBox">
                      <van-icon
                        name="photograph"
                        style="color: #999; font-size: 32px"
                      />
                      <span>添加图片</span>
                    </div>
                  </van-uploader>
                </template>
              </van-field>
            </div>
            <div
              v-for="(textItem, textIndex) in textElems"
              :key="'text' + textIndex"
              class="fieldBox"
            >
              <div class="textItemTop">
                <p class="textItemLabel">{{ textItem.key }}</p>
                <p class="wordLimit">
                  {{ textItem.text.length }}/{{ textItem.maxSize || 10 }}
                </p>
              </div>
              <van-field
                v-model="textItem.text"
                :class="[
                  'textItemInput',
                  { androidInput: !textItem.text && isAndroid }
                ]"
                :type="textItem.maxSize > 15 ? 'textarea' : 'text'"
                :name="textItem.key"
                :clearable="true"
                :autosize="autosize"
                :placeholder="'请输入' + textItem.key"
                :maxlength="textItem.maxSize || 10"
                :formatter="textFormatter"
                clear-trigger="always"
                :rules="[
                  {
                    required: textItem.enableReplace,
                    message: '请输入' + textItem.key
                  }
                ]"
              />
            </div>
          </div>
          <div class="submitBox">
            <a class="closeBut" @click="showPopupContent = false">关闭</a>
            <van-button
              class="submitBut"
              block
              round
              type="info"
              size="normal"
              native-type="submit"
              >生成</van-button
            >
          </div>
        </van-form>
      </div>
      <div v-if="listGuideStep == 2" class="second">
        <div class="uploadImage">
          <img
            src="../../assets/img/upload_image.png"
            alt="上传图片，并输入文案点击生成"
          />
        </div>
        <div class="nextButton" @click="guideNext">
          <img
            src="../../assets/img/next_button.png"
            alt="下一步"
          />
        </div>
      </div>
      <div v-if="listGuideStep == 3" class="guideLast">
        <div class="showSave">
          <div id="guidePreviewBox"></div>
          <div class="saveBut">
            <div class="saveToast">
              <van-image
                class="shareIcon"
                :src="require('@/assets/img/shareIcon.png')"
              />
              <p>长按海报立即发送给客户</p>
            </div>
          </div>
        </div>
        <div class="longPressSend">
          <img
            src="../../assets/img/long_press_send.png"
            alt="上传图片，并输入文案点击生成"
          />
        </div>
        <div class="guideWin" @click="guideWin">
          <img
            src="../../assets/img/win_button.png"
            alt="完成"
          />
        </div>
      </div>
      <div v-if="listGuide" class="checkboxParent">
        <van-checkbox v-model="remind" icon-size="14" shape="square">
          不再提醒
        </van-checkbox>
      </div>
    </van-overlay>
    <van-dialog
      v-model="showCropperDialog"
      :show-confirm-button="false"
      :close-on-click-overlay="true"
    >
      <div class="cropperBox">
        <vueCropper
          ref="cropper"
          :img="option.img"
          :output-size="option.size"
          :output-type="option.outputType"
          :info="true"
          :full="option.full"
          :can-move="option.canMove"
          :can-move-box="option.canMoveBox"
          :original="option.original"
          :auto-crop="option.autoCrop"
          :auto-crop-width="imageItem.width"
          :auto-crop-height="imageItem.height"
          :fixed-box="option.fixedBox"
        ></vueCropper>
      </div>
      <div class="upload-btn">
        <van-button type="primary" size="large" @click="uploadCropImg">
          确认上传
        </van-button>
      </div>
    </van-dialog>
    <div v-show="saveImg" class="showSave">
      <div
        id="saveImgBox"
        class="saveImgBox columnCenter"
        @touchstart="saveImage('touchstart')"
        @touchmove="saveImage('touchmove')"
        @touchend="saveImage('touchend')"
      ></div>
      <div class="saveBut">
        <div class="saveToast">
          <van-image
            class="shareIcon"
            :src="require('@/assets/img/shareIcon.png')"
          />
          <p>长按海报立即发送给客户</p>
        </div>
      </div>
    </div>
    <div v-if="previewImg" class="showSave" @click="previewImg = false">
      <img
        class="previewImg"
        :src="previewObj.sampleImgUrl"
        alt="图片加载失败，请联系管理员重新上传图片资源"
      />
    </div>
    <van-overlay :show="detailGuide" class="detailGuidePopup">
      <div class="clickCommonly">
        <img
          src="../../assets/img/click_commonly_used.png"
          alt="点击常用下次用就方便了"
        />
      </div>
      <div class="arrowDown">
        <img
          src="../../assets/img/arrow_down.png"
          alt="下箭头"
        />
      </div>
      <div class="knowButton" @click="detailGuideEnd">
        <img
          src="../../assets/img/know_button.png"
          alt="知道了"
        />
      </div>
      <div class="checkboxParent">
        <van-checkbox v-model="remind" icon-size="14" shape="square">
          不再提醒
        </van-checkbox>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { getPosterDetail, createPoster, setCommon } from '@/api/modules/poster'
const MobileDetect = require('mobile-detect')
import vueCropper from '@/components/vueCropper/vue-cropper.vue'
import { uploadBase64Img } from '@/api/modules/common'

export default {
  name: 'SelfPosterEdit',
  components: { vueCropper },
  data() {
    return {
      activeKey: 0,
      sidebarItem: [],
      showPreImg: false,
      showPopupContent: false,
      textContent: '',
      uploader: [],
      previewObj: {},
      sampleImage: null,
      imageElems: [],
      textElems: [],
      uploaderObj: {},
      option: {
        img: '', // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.6, // 剪切后的图片质量（0.1-1）
        full: false, // 输出原图比例截图 props名full
        outputType: 'jpg', // 裁剪生成额图片的格式
        canMove: true, // 能否拖动图片
        original: false, // 上传图片是否显示原始宽高
        canMoveBox: false, // 能否拖动截图框
        autoCrop: true, // 是否默认生成截图框
        fixedBox: true, // 截图框固定大小
        canScale: true // 是否开启滚轮放大缩小
      },
      showCropperDialog: false,
      imageItem: {},
      imageIndex: -1,
      imageKeyName: '',
      autosize: { maxHeight: 100, minHeight: 20 },
      saveImg: null,
      touchTimer: null,
      listGuide: false,
      listGuideStep: 1,
      remind: false,
      previewImg: false,
      detailGuide: false,
      isAndroid: false,
      showHeader: false
    }
  },
  watch: {
    showCropperDialog(val) {
      if (!val) {
        this.setImgItem()
      }
    }
  },
  async mounted() {
    const { remind, guide, id } = this.$route.query
    const ua = window.navigator.userAgent
    this.listGuide = !!guide
    this.remind = !!remind
    this.initPage(id)
    this.isAndroid = new MobileDetect(ua).os() === 'AndroidOS'
    this.showHeader = ua.indexOf('Macintosh') > -1 || ua.indexOf('Windows') > -1
  },
  methods: {
    detailGuideEnd() {
      this.detailGuide = false
      this.remind && window.localStorage.setItem('selfPosterDetailGuide', 1)
    },
    // 设置海报模板常用及取消
    setTempCommon() {
      if (this.detailGuide) return
      const item = this.previewObj
      const status = item.isCommon
      setCommon({
        commonSetting: !status ? 'SETTING' : 'CANCEL',
        materialId: item.id,
        type: '20711'
      })
        .then(res => {
          // 接口成功关闭弹窗，并给成功提示
          if (res.code === 0) {
            this.previewObj.isCommon = !status
            if (!status) {
              this.$toast('常用模板设置成功')
            }
            else {
              this.$toast('取消成功')
            }
          }
          else {
            this.$toast(res.data.msg)
          }
        })
        .catch(() => {
          this.$toast('设置失败，请稍后重试')
        })
    },
    // 点击制作海报引导完成
    guideWin() {
      this.remind && window.localStorage.setItem('userSelfPosterListGuide', 1)
      this.setSelfPosterListGuide(true)
      this.listGuide = false
      this.saveImg = null
      this.$router.back()
    },
    // 点击制作海报引导下一步
    guideNext() {
      this.addImg('saveImg', this.previewObj.sampleImgUrl)
      this.listGuideStep += 1
      this.addImg('guidePreview', this.previewObj.sampleImgUrl)
    },
    // 图片上传前处理
    beforeRead(file, key) {
      console.log(file, key, 'beforeRead@@@@@@@@@@@@@@@@')
      const type = file.type
      const typeList = ['image/jpeg', 'image/jpg', 'image/png']
      if (typeList.indexOf(type) < 0) {
        this.$toast('所选文件格式不正确，请重新选择')
        this.setImgItem()
        return
      }
      const _this = this
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = fileRes => {
        console.log(fileRes, 'fileRes')
        _this.imageIndex = key.index
        _this.uploaderObj[key.name] = fileRes.currentTarget.result
        _this.option.img = fileRes.currentTarget.result
        _this.imageKeyName = key.name
        _this.imageItem = _this.imageElems[key.index]
        const { width, height } = _this.imageItem
        _this.imageItem.width = 320
        _this.imageItem.height = (320 * height) / width
        _this.imageItem.fileName = file.name
        _this.showCropperDialog = true
      }
    },
    // base 64 转 图片流
    base64ToBlob(urlData, type) {
      const arr = urlData.split(',')
      const mime = arr[0].match(/:(.*?);/)[1] || type
      // 去掉url的头，并转化为byte
      const bytes = window.atob(arr[1])
      // 处理异常,将ascii码小于0的转换为大于0
      const ab = new ArrayBuffer(bytes.length)
      // 生成视图（直接针对内存）：8位无符号整数，长度1个字节
      const ia = new Uint8Array(ab)
      for (let i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i)
      }
      return new Blob([ab], {
        type: mime
      })
    },
    // 更新上传的海报图片
    setImgItem(data) {
      const imgItem = JSON.parse(
        JSON.stringify(this.imageElems[this.imageIndex])
      )
      if (data) {
        imgItem.imgUrl = [{ content: data }]
      }
      else {
        imgItem.imgUrl = []
      }
      this.$set(this.imageElems, this.imageIndex, imgItem)
    },
    // 文本输入款特殊字符过滤
    textFormatter(val) {
      return val.replace(
        /[^a-zA-Z0-9\u4e00-\u9fa5\u3002\uff0c\uff1a\uff08\uff09\uff1f\u201c\u201d\u3001\uff01\,\/\.\!\:\(\)\?\_\"\"\—\+\-\*\/\@\#\&\$\~\\]/gi,
        ''
      )
    },
    // 图片渲染
    addImg(key, src) {
      const _this = this
      const img = new Image()
      img.src = src
      img.onload = () => {
        const imgSize = _this.getImgSize(img.width, img.height)
        img.width = imgSize.width
        img.height = imgSize.height
        _this[key] = img
        const boxElement = document.getElementById(`${key}Box`)
        const boxChildNodes = boxElement.childNodes
        if (boxChildNodes.length > 0) {
          boxElement.removeChild(boxChildNodes[0])
        }
        boxElement.appendChild(img)
      }
    },
    // 计算渲染图片宽高
    getImgSize(w, h) {
      const pageW = window.innerWidth
      const pageH = window.innerHeight
      console.log(pageW, pageH)
      const smaW = Math.floor(pageW * 0.8)
      const smaWH = Math.floor((smaW * h) / w)
      console.log(smaW, smaWH)
      const smaH = Math.floor(pageH * 0.8)
      const smaHW = Math.floor((smaH * w) / h)
      console.log(smaH, smaHW)
      if (smaWH > smaH) {
        return {
          width: smaHW,
          height: smaH
        }
      }
      else {
        return {
          width: smaW,
          height: smaWH
        }
      }
    },
    // 模板内容过滤
    previewFilters(list) {
      const arr = []
      for (const item of list) {
        if (item.enableReplace) {
          item.imgUrl = []
          item.text = ''
          arr.push(item)
        }
      }
      return arr
    },
    // 初始化页面 获取模板详情
    async initPage(id) {
      const res = await getPosterDetail(id)
      if (res.code === 0) {
        this.previewObj = res.data
        document.title = this.previewObj.title
        const { imageElems, textElems } = JSON.parse(res.data.content)
        this.imageElems = this.previewFilters(imageElems)
        this.textElems = this.previewFilters(textElems)
        if (this.listGuide) {
          this.imageElems = this.imageElems.length
            ? [this.imageElems[0]]
            : [{ imgUrl: [], key: '上传图片' }]
          this.textElems = this.textElems.length
            ? [this.textElems[0]]
            : [{ text: '', key: '文案', maxSize: 100, enableReplace: true }]
          this.listGuideStep = 2
          this.showPopupContent = true
        }
        else {
          !this.selfPosterDetailGuide &&
            (this.detailGuide = !window.localStorage.getItem(
              'selfPosterDetailGuide'
            ))
        }
      }
      else {
        this.$toast(res.msg)
      }
    },
    // 保存图片
    saveImage(evenName) {
      if (this.touchTimer && evenName === 'touchend') {
        clearTimeout(this.touchTimer)
        this.touchTimer = null
      }
      if (!this.touchTimer && evenName === 'touchstart') {
        this.touchTimer = setTimeout(() => {
          clearTimeout(this.touchTimer)
          this.touchTimer = null
        }, 1000)
      }
    },
    // 压缩图片
    compress(img) {
      const _this = this
      // 用于压缩图片的canvas
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      // 瓦片canvas
      var tCanvas = document.createElement('canvas')
      var tctx = tCanvas.getContext('2d')
      const initSize = img.src.length
      let width = img.width
      let height = img.height
      // 如果图片大于四百万像素，计算压缩比并将大小压至400万以下
      let ratio
      if ((ratio = (width * height) / 4000000) > 1) {
        ratio = Math.sqrt(ratio)
        width /= ratio
        height /= ratio
      }
      else {
        ratio = 1
      }
      canvas.width = width
      canvas.height = height
      // 铺底色
      ctx.fillStyle = '#fff'
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      // 如果图片像素大于100万则使用瓦片绘制
      let count
      if ((count = (width * height) / 1000000) > 1) {
        count = ~~(Math.sqrt(count) + 1) // 计算要分成多少块瓦片
        // 计算每块瓦片的宽和高
        const nw = ~~(width / count)
        const nh = ~~(height / count)
        tCanvas.width = nw
        tCanvas.height = nh
        for (let i = 0; i < count; i++) {
          for (let j = 0; j < count; j++) {
            tctx.drawImage(
              img,
              i * nw * ratio,
              j * nh * ratio,
              nw * ratio,
              nh * ratio,
              0,
              0,
              nw,
              nh
            )
            ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh)
          }
        }
      }
      else {
        ctx.drawImage(img, 0, 0, width, height)
      }
      // 进行最小压缩
      const ndata = canvas.toDataURL('image/jpeg', 0.8)
      const imgSize = ndata.length / 512
      console.log(ndata.length, 'ndata.length')
      if (imgSize > 1024 * 2) {
        const imgObj = new Image()
        imgObj.src = ndata
        imgObj.onload = () => {
          return _this.compress(imgObj)
        }
      }
      else {
        console.log('压缩前：' + initSize)
        console.log('压缩后：' + ndata.length)
        console.log(
          '压缩率：' + ~~((100 * (initSize - ndata.length)) / initSize)
        )
        return ndata
      }
    },
    // 上传图片
    uploadImg(file) {
      console.log(file, 'uploadImg.file')
      const _this = this
      const formData = new FormData()
      formData.append('file', this.base64ToBlob(file))
      formData.append('key', _this.imageItem.fileName)
      uploadBase64Img(formData)
        .then(res => {
          if (res.code === 0) {
            _this.uploaderObj[_this.imageKeyName] = res.data.cosUrl
            _this.showCropperDialog = false
            const timer = setTimeout(() => {
              _this.setImgItem(file)
              clearTimeout(timer)
            }, 100)
          }
          else {
            this.$toast(res.msg || '所选图片过大')
          }
        })
        .catch(e => {
          this.$toast('图片上传失败，请重试')
        })
    },
    // 裁剪图片 并处理
    uploadCropImg() {
      const _this = this
      this.$refs.cropper.getCropData(data => {
        const imgSize = data.length / 512
        console.log(data.length, 'data.length')
        if (imgSize > 1024 * 2) {
          const imgObj = new Image()
          imgObj.src = data
          imgObj.onload = () => {
            const newImg = _this.compress(imgObj)
            _this.uploadImg(newImg)
          }
        }
        else {
          _this.uploadImg(data)
        }
      })
    },
    // 显示制作model
    showPopup() {
      if (!this.previewObj.id) return
      if (this.imageElems.length > 0 || this.textElems.length > 0) {
        this.showPopupContent = true
      }
      else {
        this.onSubmit({})
      }
    },
    // 表单验证
    onFailed(err) {
      console.log(err)
      if (!this.showPopupContent) return
      err.errors[0] && this.$toast(err.errors[0].message)
    },
    // 制作海报
    onSubmit(values) {
      console.log('submit', values)
      console.log('this.uploaderObj', this.uploaderObj)

      createPoster({
        content: {
          ...values,
          ...this.uploaderObj
        },
        templateId: this.previewObj.id
      })
        .then(res => {
          if (res.code === 0) {
            this.addImg('saveImg', res.data.detailUrl)
            this.showPopupContent = false
            // this.imageElems = this.previewFilters(this.imageElems)
            // this.textElems = this.previewFilters(this.textElems)
          }
          else {
            this.$toast('海报生成失败，' + res.msg)
          }
        })
        .catch(() => {
          this.$toast('海报生成失败，请重试')
        })
    }
  },
  head() {
    return {
      title: '自制海报'
    }
  },
  beforeRouteLeave(to, from, next) {
    // 离开页面
    if (this.listGuide) {
      next(false)
      return
    }
    if (this.saveImg && !this.listGuide) {
      this.saveImg = null
      next(false)
    }
    else {
      next(true)
    }
  }
}
</script>

<style scoped lang="less">
.container {
  height: 100vh;
  background: #fff;
  .content {
    display: flex;
    flex-direction: column;
    padding: 0 12px 36px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    .sampleImageBox {
      flex: 1;
      overflow-y: scroll;
      img {
        width: 100%;
      }
    }
    .bottomButBox {
      display: flex;
      justify-content: space-between;
      padding: 12px 4px 12px 12px;
      height: 44px;
      .smallBut {
        padding: 0 10px;
        width: 46px;
        text-align: center;
        color: #999999;
        line-height: 1;
        .iconfont {
          font-size: 26px;
          position: relative;
          top: -5px;
        }
        .iconsheweichangyongyixuan {
          color: #287de1;
        }
        .smallButText {
          margin-top: -2px;
          font-size: 12px;
        }
      }
      .setUsually {
        width: 220px;
        display: block;
        font-size: 18px;
        color: #fff;
        height: 44px;
        line-height: 44px;
        text-align: center;
        border: solid 1px #fff;
        background: #2c78ea;
        border-radius: 4px;
      }
      .detailGuideCommon {
        position: relative;
        z-index: 999999;
        background: #fff;
        border-radius: 4px;
      }
    }
  }
  .makeBox {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    padding: 16px;
    width: 80%;
    min-height: 90px;
    max-height: 80vh;
    background-color: #fff;
    transition: transform 0.3s;
    .makeBoxTitle {
      margin-bottom: 16px;
      font-size: 16px;
      line-height: 22px;
    }
    .fieldBox {
      .textItemTop {
        display: flex;
        justify-content: space-between;
        .textItemLabel {
          padding: 0 16px;
          span {
            color: #e40000;
          }
        }
        .wordLimit {
          position: relative;
          top: 6px;
          padding-right: 16px;
          font-size: 14px;
          color: #999;
        }
      }
    }
    .uploaderBox {
      width: 100%;
      height: 116px;
      display: flex;
      flex-direction: column;
      border: dashed 1px rgba(153, 153, 153, 0.5);
      border-radius: 4px;
      font-size: #2c78ea;
      justify-content: center;
      align-items: center;
      color: #999 !important;
      span {
        margin-top: 10px;
      }
    }
    .formBox {
      max-height: calc(80vh - 130px);
      width: calc(100% + 32px);
      margin-left: -16px;
      overflow: scroll;
      .imguploaderBox {
        position: relative;
        padding-top: 24px;
        .imguploaderLabel {
          position: absolute;
          top: 0;
          left: 16px;
          z-index: 999;
          font-size: 14px;
          background: #fff;
          span {
            color: #e40000;
          }
        }
      }
      .imguploaderLabel,
      .textItemLabel {
        position: relative;
        &::before {
          content: '';
          display: inline-block;
          position: relative;
          top: 1px;
          margin-right: 10px;
          width: 2px;
          height: 14px;
          background: #287de1;
          border-radius: 2px;
        }
      }
    }
    .submitBox {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
      .submitBut,
      .closeBut {
        width: 45%;
        height: 36px;
        line-height: 36px;
        font-size: 16px;
      }
      .closeBut {
        text-align: center;
        color: #999;
        background: #e8e8e8;
        border-radius: 4px;
        border: none;
        box-shadow: none;
      }
    }
  }
  .guidePopup {
    transform: translate3d(-50%, -80%, 0) scale3d(0.7, 0.7, 1);
    max-height: 66vh;
    border-radius: 4px;
  }
  .second,
  .guideLast {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    > div {
      position: absolute;
      left: 50%;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .second {
    .uploadImage {
      top: 66%;
      margin-left: -127px;
      width: 254px;
      height: 18px;
    }
    .nextButton {
      top: 74%;
      margin-left: -54px;
      width: 108px;
      height: 57px;
    }
  }
  .guideLast {
    .showSave {
      padding: 0 10px 20px;
      width: auto;
      height: auto;
      transform: translate3d(-50%, -15%, 0) scale3d(0.6, 0.6, 1);
      text-align: center;
      border-radius: 4px;
    }
    .saveToast {
      margin-top: 20px;
    }
    .longPressSend {
      top: 66%;
      margin-left: -81px;
      width: 162px;
      height: 18px;
    }
    .guideWin {
      top: 74%;
      margin-left: -54px;
      width: 108px;
      height: 57px;
    }
  }
  .van-overlay {
    z-index: 10;
  }
  .checkboxParent {
    position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -40px;
    width: 80px;
    /deep/ .van-checkbox__icon .van-icon {
      border-radius: 3px;
    }
  }
  .detailGuidePopup {
    img {
      width: 100%;
      height: 100%;
    }
    > div {
      position: absolute;
    }
    .clickCommonly {
      left: 89px;
      bottom: 286px;
      width: 198px;
      height: 22px;
    }
    .arrowDown {
      left: 42px;
      bottom: 96px;
      width: 44px;
      height: 190px;
    }
    .knowButton {
      left: 50%;
      bottom: 193px;
      margin-left: -54px;
      width: 108px;
      height: 58px;
    }
  }
}
.cropperBox {
  height: 60vh;
}
.upload-btn {
  margin: 0 auto;
  width: 100%;
}
.showSave {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow-y: scroll;
  z-index: 5;
  .saveImgBox {
    box-sizing: content-box;
    margin: 32px auto 16px;
    max-width: 80%;
    min-height: 80%;
    background: rgba(44, 120, 234, 0.1);
    padding: 10px;
    border-radius: 2px;
    img {
      display: block;
      margin: 0 auto;
    }
  }
  .saveBut {
    margin: 0 auto;
    width: 326px;
  }
  .saveToast {
    text-align: center;
    padding-bottom: 16px;
    .shareIcon {
      display: inline-block;
      position: relative;
      top: 6px;
      width: 24px;
      height: 24px;
    }
    p {
      display: inline-block;
      padding-left: 8px;
      font-size: 18px;
      line-height: 24px;
      color: #999;
    }
  }
  .previewImg {
    width: 100%;
  }
}
.columnCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.flexColumn {
  display: flex;
  flex-direction: column;
}
</style>
<style lang="less">
.container {
  p {
    margin: 5px 0;
    padding: 0;
  }
  .textItemTop {
    font-size: 14px;
  }
  .van-sidebar-item {
    background-color: #fff;
    padding: 14px 12px;
  }
  .van-sidebar-item--select {
    background-color: #f5f5f5;
    color: #2c78ea;
  }
  .van-uploader {
    width: 100%;
  }
  .van-uploader__wrapper {
    width: 100%;
  }
  .van-uploader__input-wrapper {
    width: 100%;
  }
  .van-field__control {
    color: #2c78ea;
    font-size: 14px;
  }
  .van-icon-photograph {
    font-size: 32px;
  }
  .van-button--round {
    border-radius: 4px;
  }
  .fieldBox {
    .van-field__body {
      padding: 8px 13px;
      background: #f0f0f0;
      border-radius: 4px;
      .van-field__control {
        height: 16px;
        line-height: 16px;
      }
      .van-field__clear {
        line-height: 16px;
      }
    }
    .androidInput {
      .van-field__body {
        padding-top: 9px;
        padding-bottom: 7px;
      }
    }
  }
  .van-checkbox__label {
    font-size: 14px;
    color: #e8e8e8;
  }
}
</style>
